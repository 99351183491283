angular.module("app").service('_formatService',  function ($rootScope, _view, _appStorage) {

    this.BUNDLE = {
        DATE_WITH_TIME: {
            'en': 'MM/DD/YY hh:mm:ss A',
            'pt': 'DD.MM.YY HH:mm:ss',
            'ru': 'DD.MM.YY HH:mm:ss'
        },
        DATE: {
            'en': 'MM/DD/YY',
            'pt': 'DD.MM.YY',
            'ru': 'DD.MM.YY'
        },
        DATEPICKER: {
            'en': 'MM/dd/yy',
            'pt': 'dd.MM.yy',
            'ru': 'dd.MM.yy'
        }
    };

    function getLang() {
        var lang = _appStorage.get('lang') || "en";

        if (lang.indexOf('pt') != -1) {
            return 'pt';
        }

        return lang;
    }

    this.formatDateAndTime = function(date, timezone) {
        if (timezone) {
            return moment.tz(date, timezone).format(this.BUNDLE.DATE_WITH_TIME[getLang()]);
        }
        return moment.utc(date).format(this.BUNDLE.DATE_WITH_TIME[getLang()]);
    };

    this.formatDate = function(date) {
        return moment.utc(date).format(this.BUNDLE.DATE[getLang()]);
    };

    this.getDatePickerFormatForDate = function() {
        //console.log("getLang", getLang());

        return this.BUNDLE.DATEPICKER[getLang()];
    };

});
